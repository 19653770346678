<template>
  <v-dialog :value="open" persistent max-width="600">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-account-edit</v-icon>
        Vendégbelépés részletei
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <div class="font-weight-bold">Belépés helye</div>
            <div class="text-body-1">{{ form.entry_gate_name }}</div>
          </v-col>
          <v-col>
            <div class="font-weight-bold">Beléptető felhasználó</div>
            <div class="text-body-1">{{ form.entry_user_name }}</div>
          </v-col>
          <v-col>
            <div class="font-weight-bold">Belépés ideje</div>
            <div class="text-body-1">{{ form.entry_datetime | moment('lll') }}</div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text class="mt-4">
        <v-row v-if="form.exit_datetime">
          <v-col>
            <div class="font-weight-bold">Kilépés helye</div>
            <div class="text-body-1">{{ form.exit_gate_name }}</div>
          </v-col>
          <v-col>
            <div class="font-weight-bold">Kiléptető felhasználó</div>
            <div class="text-body-1">{{ form.exit_user_name }}</div>
          </v-col>
          <v-col>
            <div class="font-weight-bold">Kilépés ideje</div>
            <div class="text-body-1">{{ form.exit_datetime | moment('lll') }}</div>
          </v-col>
        </v-row>
        <v-row v-else align-content-center>
          <v-col>
            <v-autocomplete
              label="Kilépés helye"
              v-model="form.exit_gate_id"
              :items="gates"
              filled
              item-text="name"
              item-value="element_id"
              :hide-details="!errors.exit_gate_id"
              :error-messages="errors.exit_gate_id"
              dense
            />
          </v-col>
          <v-col>
            <v-btn color="primary" depressed block x-large @click="exit" :loading="loading">Kiléptetés</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <div class="font-weight-bold">Név</div>
            <div class="text-body-1">{{ form.name }}</div>
          </v-col>
          <v-col>
            <div class="font-weight-bold">Cég</div>
            <div class="text-body-1">{{ value.company_name || '-' }}</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="font-weight-bold">Születési idő</div>
            <div class="text-body-1">{{ form.birth_date ? ($moment(form.entry_datetime).format('ll')) : '-' }}</div>
          </v-col>
          <v-col>
            <div class="font-weight-bold">Cím</div>
            <div class="text-body-1">{{ value.address || '-' }}</div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <div class="font-weight-bold">Telefonszám</div>
            <div class="text-body-1">{{ form.phone_number || '-' }}</div>
          </v-col>
          <v-col>
            <div class="font-weight-bold">Vendégkitűző száma</div>
            <div class="text-body-1">{{ value.badge_number || '-' }}</div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <div class="font-weight-bold">Megjegyzés</div>
            <div class="text-body-1">{{ value.remark || '-' }}</div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text class="mt-4" v-if="JSON.parse(value?.extra_fields).length">
        <v-card-title class="pa-0 mb-3"> Extra mezők </v-card-title>

        <v-row
          v-for="(field, index) in JSON.parse(value?.extra_fields)"
          :key="'guest-extra-field-' + index"
        >
          <v-col>
            <div class="font-weight-bold">{{ field.key }}</div>
            <div class="text-body-1">{{ field.value || '-' }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, Boolean],
      default: false,
    },
  },

  data() {
    return {
      form: {},
      errors: {},
      loading: false,
    };
  },

  watch: {
    value: {
      handler() {
        this.form = this.value;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async exit() {
      try {
        this.loading = true;
        this.errors = {};
        const response = await this.$http.post('guest-log/exit', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          this.$dialog.notify.success('Sikeres mentés!');
          this.close();
          this.$emit('success');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit('input', false);
    },
  },

  computed: {
    open() {
      return !!this.value;
    },
  },

  asyncComputed: {
    gates: {
      async get() {
        const response = await this.$http.post('gates/list', { reader_direction: 'O', limit: 9999 });
        return response.gates;
      },
      default: [],
    },
  },
};
</script>
