<template>
  <v-dialog :value="isOpen" persistent max-width="720" scrollable>
    <v-form @submit.prevent="entry">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-account-edit</v-icon>
          Új vendégbelépés
          <v-spacer />
          <v-btn icon @click="isOpen = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text style="max-height: calc(100vh - 200px)">
          <v-autocomplete
            label="Kapu"
            v-model="form.entry_gate_id"
            :items="gates"
            filled
            item-text="name"
            item-value="element_id"
            :error-messages="errors.entry_gate_id"
            hint="Kötelező"
            persistent-hint
            class="ma-2"
          />

          <v-text-field
            label="Vendég neve"
            v-model="form.name"
            filled
            :error-messages="errors.name"
            v-mask="$config.inputMasks.name"
            persistent-hint
            hint="Kötelező"
            class="ma-2"
          />

          <dynamic-field
            v-for="field in extraFields"
            :key="`extra-field-${field.name}`"
            :field="field"
            v-model="form.extra_fields[field.name]"
            :error-messages="errors[field.name]"
            filled
            class="ma-2"
          />

          <v-textarea
            label="Megjegyzés"
            v-model="form.remark"
            filled
            :error-messages="errors.remark"
            rows="3"
            auto-grow
            class="ma-2"
          />

          <!--
          <div v-show="showDetails">
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-datetime-picker
                  label="Születési idő"
                  v-model="form.birth_date"
                  filled
                  class="ma-2"
                  :error-messages="errors.birth_date"
                  :hide-details="!errors.birth_date"
                  :time-picker="false"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Cím"
                  v-model="form.address"
                  filled
                  :error-messages="errors.address"
                  class="ma-2"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Telefonszám"
                  v-model="form.phone_number"
                  filled
                  :hide-details="!errors.phone_number"
                  :error-messages="errors.phone_number"
                  v-mask="$config.inputMasks.phoneNumber"
                  class="ma-2"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Vendégkitűző száma"
                  v-model="form.badge_number"
                  filled
                  :error-messages="errors.badge_number"
                  v-mask="$config.inputMasks.cardNumber"
                  class="ma-2"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Rendszám"
                  v-model="form.plate_number"
                  filled
                  :error-messages="errors.plate_number"
                  v-mask="$config.inputMasks.plateNumber"
                  class="ma-2"
                />
              </v-col>
            </v-row>
          </div>
<div v-show="showDetails">
            <div
              v-for="(field, index) in form.extra_fields"
              :key="'guest-extra-field-' + index"
              class="d-flex align-top"
            >
              <v-text-field
                placeholder="Mező megnevezése"
                v-model="field.key"
                filled
                class="ma-2"
                :hide-details="
                  !errors[`extra_field-${index}-value`] && !errors[`extra_field-${index}-key`]
                "
                :error-messages="errors[`extra_field-${index}-key`]"
              />
              <v-text-field
                placeholder="Érték"
                v-model="field.value"
                filled
                class="ma-2"
                :hide-details="
                  !errors[`extra_field-${index}-value`] && !errors[`extra_field-${index}-key`]
                "
                :error-messages="errors[`extra_field-${index}-value`]"
              />
              <v-btn icon fab class="rounded" @click="form.extra_fields.splice(index, 1)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <div class="d-flex">
              <v-spacer />
              <v-btn
                v-if="form.extra_fields.length < 4"
                text
                outlined
                @click="form.extra_fields.push({ key: '', value: '' })"
                class="my-3"
              >
                <v-icon left>mdi-plus</v-icon>
                Extra mező hozzáadása
              </v-btn>
              <v-spacer />
            </div>
          </div>

          <div class="d-flex">
            <v-spacer />
            <v-btn small text depressed @click="showDetails = !showDetails">
              <v-icon left>{{ showDetails ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
              <div v-if="showDetails">További adatok elrejtése</div>
              <div v-else>További adatok megjelenítése</div>
            </v-btn>
            <v-spacer />
          </div> -->
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" large color="primary" depressed :loading="loading">
            <v-icon left>mdi-check</v-icon>
            Mentés
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DynamicField from '@/components/DynamicField.vue';

export default {
  components: { DynamicField },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      errors: {},
      form: {
        extra_fields: [],
      },
      showDetails: false,
      isOpen: false,
    };
  },

  watch: {
    value() {
      this.isOpen = this.value;
    },
  },

  methods: {
    async entry() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('guest-log/entry', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          this.$dialog.notify.success('Sikeres mentés!');
          this.$emit('success');
          this.$emit('close');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isOpen = false;
        this.loading = false;
      }
    },

    reset() {
      this.form = {
        extra_fields: [],
      };
    },
  },

  computed: {
    extraFields() {
      return this.$store.getters['fields/findByModelName']('GuestLog');
    },
  },

  asyncComputed: {
    gates: {
      async get() {
        const response = await this.$http.post('gates/list', {
          reader_direction: 'I',
          limit: 9999,
        });
        return response.gates;
      },
      default: [],
    },

    companies: {
      async get() {
        const response = await this.$http.post('guest-log/companies/list', {
          limit: 9999,
        });
        return response.companies;
      },
      default: [],
    },
  },
};
</script>
